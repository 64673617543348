import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/antonio-escritorio/Documents/moare/sites-estaticos/libres-de-violencias/src/components/default-mdx-layout.js";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Container, Grid, Flex, Themed } from "theme-ui";
import SpeakerSummary from "../components/speaker-summary";
import JailImage from "../components/jail-image";
import Button from "../components/button";
import AxisSummary from "../components/axis-summary";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
      <Flex sx={{
        alignItems: [`flex-start`, `flex-start`, `flex-start`, `center`, `center`, `center`],
        justifyContent: `flex-start`,
        flexDirection: [`column`, `column`, `column`, `initial`, `initial`, `initial`]
      }} mdxType="Flex">
        <Box sx={{
          marginRight: [0, 0, 0, 9, 9, 10],
          h1: {
            fontSize: [4, 4, 4, 5, 6, 7],
            marginTop: 6,
            textTransform: `uppercase`
          },
          "p:last-child": {
            marginBottom: 0
          }
        }} mdxType="Box">
          <h1>{`¡Construyamos`}<br />{`territorios libres de`}<br />{`violencias machistas!`}</h1>
          <h2>{`Encuentros internacionales feministas`}<br />{`14-15-16 de octubre de 2021`}</h2>
          <p>{`Voces feministas desde Cataluña, Estado Español y América Latina.`}</p>
        </Box>
        <JailImage mdxType="JailImage" />
      </Flex>
    </Container>
    <Box sx={{
      backgroundColor: `white`,
      py: 4
    }} mdxType="Box">
      <Container mdxType="Container">
        <Grid gap={[5, 5, 5, 7, 7, 7]} sx={{
          gridTemplateColumns: `repeat(auto-fit, minmax(11.39rem, 1fr))`,
          py: 6
        }} mdxType="Grid">
          <Box mdxType="Box">
            <Box sx={{
              fontFamily: `body`,
              fontSize: 0,
              fontWeight: `bold`
            }} mdxType="Box">[Castellano]</Box>
            <p>{`Los días 14, 15 y 16 de octubre de 2021 se celebraron los Encuentros Internacionales Feministas «¡Construimos territorios libres de violencias machistas! Voces feministas desde Catalunya, el estado español y América Latina» en la ciudad de Barcelona. `}<Themed.a href="https://www.entrepueblos.org/">{`Entrepueblos`}</Themed.a>{` en coordinación con `}<Themed.a href="https://www.curcuma.coop/es/portada/" rel="external">{`Cúrcuma`}</Themed.a>{` nos propusimos generar un espacio de `}<strong parentName="p">{`diálogos entre feministas`}</strong>{` para identificar los principales `}<strong parentName="p">{`debates y retos locales/globales de los feminismos en torno a las violencias machistas`}</strong>{`.`}</p>
            <p>{`En concreto, decidimos profundizar sobre dos grandes temas -las `}<strong parentName="p">{`violencias sexuales en jóvenes y adolescentes`}</strong>{`, y las `}<strong parentName="p">{`maternidades cuestionadas y retiradas de custodias`}</strong>{` a los que dedicamos una mesa abierta con la participación de diversas ponentes las tardes del 14 y 15 de octubre (se pueden ver ambas mesas en esta página), y también un espacio de debate interno (el viernes 15 por la mañana) en el que contamos con la participación de colectivos de madres afectadas, organizaciones y entidades feministas, y representantes de administraciones públicas.`}</p>
            <p>{`Cerramos los encuentros el sábado 16 por la mañana con una mesa para recoger múltiples reflexiones en torno a las violencias machistas a través de voces de feministas catalanas, españolas y latinoamericanas (que también puede verse aquí).`}</p>
            <p>{`Durante los Encuentros pudimos contar con las propuestas artivistas tanto de las Amigas de Yoli, en el inicio de las Jornadas, como de las compañeras del Colectivo de Mujeres de Matagalpa que con sus «Aperturas» facilitaron un espacio de cierre para recoger los diálogos feministas generados durante los tres días. `}</p>
          </Box>
          <Box mdxType="Box">
            <Box sx={{
              fontFamily: `body`,
              fontSize: 0,
              fontWeight: `bold`
            }} mdxType="Box">[Català]</Box>
            <p>{`Els dies 14, 15 i 16 d'octubre de 2021 es van celebrar les Trobades Internacionals Feministes "Construïm territoris lliures de violències masclistes! Veus feministes des de Catalunya, l'estat espanyol i Amèrica Llatina" a la ciutat de Barcelona. `}<Themed.a href="https://www.entrepueblos.org/">{`Entrepueblos`}</Themed.a>{` en coordinació amb `}<Themed.a href="https://www.curcuma.coop/es/portada/" rel="external">{`Cúrcuma`}</Themed.a>{` ens vam proposar generar un espai de `}<strong parentName="p">{`diàlegs entre feministes`}</strong>{` per a `}<strong parentName="p">{`identificar els principals debats i reptes locals/globals dels feminismes entorn de les violències masclistes`}</strong>{`.`}</p>
            <p>{`En concret, vam decidir aprofundir sobre dos grans temes -les `}<strong parentName="p">{`violències sexuals en joves i adolescents`}</strong>{`, i `}<strong parentName="p">{`les maternitats qüestionades i retirades de custòdies`}</strong>{` als quals vam dedicar una taula oberta amb la participació de diverses ponents les tardes del 14 i 15 d'octubre (es poden veure totes dues taules en aquesta pàgina), i també un espai de debat intern (el divendres 15 al matí) on vam comptar amb la participació de col·lectius de mares afectades, organitzacions i entitats feministes, i representants d'administracions públiques.`}</p>
            <p>{`Tanquem les trobades el dissabte 16 al matí amb una taula per a recollir múltiples reflexions entorn de les violències masclistes a través de veus de feministes catalanes, espanyoles i llatinoamericanes (que també pot veure's aquí).`}</p>
            <p>{`Durant les Trobades vam poder comptar amb les propostes artivistas tant de les Amigas de Yoli, a l'inici de les jornades, com de les companyes del Colectivo de Mujeres de Matagalpa que amb les seves “Aperturas” van facilitar un espai de tancament per a recollir els diàlegs feministes generats durant els tres dies.`}</p>
          </Box>
        </Grid>
      </Container>
    </Box>
    <Box sx={{
      py: 8,
      textAlign: `center`
    }} mdxType="Box">
      <Button url="/presentacion" text="Presentación" mdxType="Button" />
    </Box>
    <Container sx={{
      borderTop: theme => `1px solid ${theme.colors.primarylightest}`,
      py: 5
    }} mdxType="Container">
      <Grid gap={[4, 4, 4, 5, 5, 6]} sx={{
        gridTemplateColumns: [`1fr`, `1fr`, `repeat(auto-fit, minmax(5.06rem, 1fr))`, `repeat(auto-fit, minmax(11.39rem, 1fr))`, `repeat(auto-fit, minmax(17.08rem, 1fr))`, `repeat(auto-fit, minmax(17.08rem, 1fr))`]
      }} mdxType="Grid">
        <Box id="ejes-tematicos" sx={{
          ".subtitle": {
            fontSize: 2,
            fontWeight: `200`,
            marginTop: 0
          }
        }} mdxType="Box">
          <h2 className="subtitle">Ejes temáticos</h2>
        </Box>
        <AxisSummary title="Plantemos cara a las violencias sexuales en jóvenes y adolescentes: respuestas y retos desde diferentes ámbitos y geografías" link="plantemos-cara-a-las-violencias-sexuales-en-jovenes-y-adolescentes" mdxType="AxisSummary" />
        <AxisSummary title="Maternidades cuestionadas y retirada de custodias: violencia institucional, racismo y falso SAP" link="maternidades-cuestionadas-y-retirada-de-custodias-violencia-institucional-racismo-y-falso-sap" mdxType="AxisSummary" />
        <AxisSummary title="Reflexiones en torno a las violencias machistas a través de diversas voces feministas desde Cataluña, el Estado Español y América Latina" link="reflexiones-en-torno-a-las-violencias-machistas-a-traves-de-diversas-voces-feministas" mdxType="AxisSummary" />
      </Grid>
    </Container>
    <Container sx={{
      borderTop: theme => `1px solid ${theme.colors.primarylightest}`,
      paddingTop: 5
    }} mdxType="Container">
      <Grid gap={[4, 4, 4, 5, 5, 6]} sx={{
        gridTemplateColumns: [`1fr`, `1fr`, `repeat(auto-fit, minmax(5.06rem, 1fr))`, `repeat(auto-fit, minmax(11.39rem, 1fr))`, `repeat(auto-fit, minmax(17.08rem, 1fr))`, `repeat(auto-fit, minmax(17.08rem, 1fr))`]
      }} mdxType="Grid">
        <Box id="participantes" sx={{
          marginTop: [6, 6, 0, 0, 0, 0],
          ".subtitle": {
            fontSize: 2,
            fontWeight: `200`
          }
        }} mdxType="Box">
          <h2 className="subtitle">Participantes</h2>
        </Box>
        <Box mdxType="Box">
         <SpeakerSummary name='Emilia Salazar Chávez' country='Perú' presentation='Luchando por vidas libres de violencias para las niñas y adolescentes peruanas. Las experiencias feministas de Demus' profession='Socióloga feminista e integrante del equipo DEMUS' mdxType="SpeakerSummary" />
          <SpeakerSummary name='Barbara Biglia' country='Tarragona' presentation='Diálogos cross-oceánicos entre políticas y acción directas para el abordaje de las violencias de género y sexuales en las universidades' profession='Profesora del departamento de Pedagogía de la Universitat Rovira i Virgili e investigadora principal del proyecto SeGReVUni' mdxType="SpeakerSummary" />
        </Box>
        <Box mdxType="Box">
          <SpeakerSummary name='Soledad González' country='Uruguay' presentation='¿Por qué las instituciones españolas violan sistemáticamente los derechos humanos de niños, niñas y adolescentes víctimas de abuso sexual infantil entregándoselos a sus abusadores?' profession='Feminista, politóloga, integrante de Cotidiano Mujer' mdxType="SpeakerSummary" />
          <SpeakerSummary name='Rebeca Gregson Tovar' country='Barcelona' presentation='Migración, racismo y maternidad: construyendo nuevos horizontes de sentido' profession='Madre migrada, psicóloga social especialista en participación y acción comunitaria. La Tregua e IPECAL' mdxType="SpeakerSummary" />
          <SpeakerSummary name='Silvia Aquiles' country='Barcelona' presentation='Falso SAP (Síndrome de Alienación Parental) y sus consecuencias institucionales y jurídicas para madres e hijos víctimas de malos tratos' profession='Activista anti-SAP' mdxType="SpeakerSummary" />
          <SpeakerSummary name='Daniela Ortiz' country='Perú' presentation='Maternidades migrantes y racismo institucional' profession='Artista, activista y migrante anticolonial ' mdxType="SpeakerSummary" />
        </Box>
        <Box mdxType="Box">
          <SpeakerSummary name='Lolita Chávez' country='Guatemala' presentation='Violencias machistas y extractivismo en Abya Yala' profession='Indígena guatemalteca, activista y feminista defensora de los DDHH' mdxType="SpeakerSummary" />
          <SpeakerSummary name='Pastora Filigrana' country='Sevilla' presentation='Las violencias institucionales que sufren las trabajadoras migrantes' profession='Abogada y defensora de Derechos Humanos' mdxType="SpeakerSummary" />
          <SpeakerSummary name='Lilián Celiberti' country='Uruguay' presentation='Cuerpos indisciplinados y resistencias al poder: tocan a una tocan a todas' profession='Feminista e integrante de Cotidiano Mujer' mdxType="SpeakerSummary" />
          <SpeakerSummary name='Montserrat Cervera' country='Barcelona' presentation='Feminismos y acuerpamiento antipatriarcal desde Cataluña: oportunidades, retos y alternativas' profession='Activista feminista de Novembre Feminista' mdxType="SpeakerSummary" />
        </Box>
      </Grid>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      