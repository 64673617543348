import React from "react"
import { Link } from "gatsby"
import { Card, Themed } from "theme-ui"
import getSlug from "speakingurl"

const SpeakerSummary = ( props ) => {

  const nameSpeaker = props.name
  const countrySpeaker = props.country
  const titlePresentation = props.presentation
  const professionSpeaker = props.profession
  const linkSpeaker = getSlug(nameSpeaker)

  return (

    <Card
      sx={{
        py: 3,
        borderBottom: theme => `1px solid ${theme.colors.primarylightest}`,
        a: {
          textDecoration: `none`,
          "&:hover, &:active, &:focus": {
            textDecoration: `underline`
          }
        },
        "p": {
          marginBottom: 0,
        },
        ".profession": {
          fontWeight: `light`,
        },
        ".country": {
          fontWeight: `light`,
        },
        ".title-presentation": {
          fontSize: 0,
          marginTop: 4,
          marginBottom: 2
        }
      }}
    >
      <Themed.h3>
        <Themed.a
          as={Link}
          to={`/${linkSpeaker}`}
          rel="bookmark"
        >
          {nameSpeaker}
        </Themed.a>
      </Themed.h3>
      <Themed.p className="profession">{professionSpeaker}</Themed.p>
      <Themed.p className="country">{countrySpeaker}</Themed.p>
      <Themed.p className="title-presentation">{titlePresentation}</Themed.p>
    </Card>
  
  )

}

export default SpeakerSummary