import React from "react"
import { Link } from "gatsby"
import { Flex, Themed } from "theme-ui"

const AxisSummary = ( props ) => {

  return (
    <Flex
      sx={{
        flexDirection: `column`,
        justifyContent: `space-between`,
        marginBottom: [4, 0],
        h2: {
          marginTop: 0,
        },
        a: {
          fontWeight: `body`,
          display: `block`,
          marginTop: [2, 4],
        }
      }}
    >

    <Themed.h2>{props.title}</Themed.h2>
    <Themed.a as={Link} className="link-to-eje" to={`/${props.link}`}>Ver &#x02192;</Themed.a>
    </Flex>
  )

}

export default AxisSummary